(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/libs/draft-js/assets/javascripts/string.ends-with.js') >= 0) return;  svs.modules.push('/components/libs/draft-js/assets/javascripts/string.ends-with.js');
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function(search, this_len) {
    if (this_len === undefined || this_len > this.length) {
      this_len = this.length;
    }
    return this.substring(this_len - search.length, this_len) === search;
  };
}


 })(window);